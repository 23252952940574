.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-title{
  margin: 5px 15px 10px 30px;
}

.btn-reject{
  color: #E74C3C !important;
  background-color: white !important;
  border: none !important;
  font-weight: bold !important;
}
.btn-reject:hover{
  background-color: #F8F8FF !important;
}

.btn-suggest{
  color: #D5971A !important;
  background-color: white !important;
  border: none !important;
  font-weight: bold !important;

}

.btn-suggest:hover{
  background-color: #F8F8FF !important;
}

.btn-accept{
  color: white !important;
  background-color: #0f4591 !important;
  font-weight: bold !important;
}
.btn-accept:hover{
  background-color: #1358b9 !important;
}

.btn{
  border-radius: 10px !important;
  margin: 50px 10px 10px 10px;
  font-family: Inter-SemiBold;
  letter-spacing: 1px;
  
}

.text-title{
  margin:50px 10px 10px 10px ;
}

.text-space{
  margin: 35px 10px 20px 10px;
  letter-spacing: 1px;
}

.letter-space{
  letter-spacing: 2px;
}

.modal-element{
  margin-top: 7px;
  margin-bottom: 7px;
}

p{
  font-size: 16px;
}

.text-underline{
  text-decoration: underline;
}

.font-large{
  font-size: 18px !important;
}

.e-icons{
  font-size: 20px !important;
  margin-right: 5px !important;
}

.e-datetime-wrapper .e-time-icon.e-icons::before{
  font-size: 20px;
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";